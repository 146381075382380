import isServer from '../utils/isServer';

const ignoreErrorsSet = new Set(['ApiError', 'OpmlError', 'URIError']); // TODO: move to ignoreErrors after web-api-client change

export function shouldIgnoreEvent(event, hint) {
  const originalException = hint?.originalException;
  const exceptionValues = event?.exception?.values;
  const stacktrace = exceptionValues?.[0]?.stacktrace;
  const responseStatus = originalException?.response?.status;
  const userAgent = !isServer() && window.navigator?.userAgent;

  // Ignore explicit error types that we don't want to send to Sentry as they are currently sent to NewRelic
  if (
    ignoreErrorsSet.has(originalException?.name) ||
    ignoreErrorsSet.has(exceptionValues?.[0]?.type)
  ) {
    return true;
  }

  // Rate limit our logs for 429 Errors to Sentry
  if (responseStatus === 429) {
    // We would like to limit the number of 429 errors sent so only about 20% of them are sent to Sentry
    if (Math.random() > 0.2) {
      return true;
    }
  }

  // Ignore Electron errors from the desktop app until we upgrade Electron
  if (userAgent?.includes('Electron/4.2.8')) {
    return true;
  }

  // Temporarily ignore errors triggered from hls.js
  if (
    stacktrace?.frames?.some?.((frame) =>
      frame?.module?.includes(
        'assets/js/gemini-web/node_modules/hls.js/dist/hls',
      ),
    )
  ) {
    return true;
  }

  // Google Recaptcha errors are not useful for us to track
  if (
    event.message?.includes(
      'Non-Error promise rejection captured with value: Timeout',
    )
  ) {
    return true;
  }

  if (exceptionValues) {
    // Third-party ad networks will sometimes throw SecurityError exceptions
    return exceptionValues.some(
      ({ type, value }) =>
        type === 'SecurityError' &&
        value?.includes(
          'An attempt was made to break through the security policy',
        ),
    );
  }

  return false;
}
